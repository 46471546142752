/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
//==============================================================
// START Enums and Input Objects
//==============================================================
export var AllocationMethod;
(function (AllocationMethod) {
    AllocationMethod["registration_group_single_event"] = "registration_group_single_event";
    AllocationMethod["school_divide"] = "school_divide";
    AllocationMethod["school_single_event"] = "school_single_event";
})(AllocationMethod || (AllocationMethod = {}));
export var ApplicationStatus;
(function (ApplicationStatus) {
    ApplicationStatus["accept_to_protocol_for_approval"] = "accept_to_protocol_for_approval";
    ApplicationStatus["accepted"] = "accepted";
    ApplicationStatus["draft"] = "draft";
    ApplicationStatus["not_access"] = "not_access";
    ApplicationStatus["preliminary_accepted"] = "preliminary_accepted";
    ApplicationStatus["preliminary_rejected"] = "preliminary_rejected";
    ApplicationStatus["reject_to_protocol_for_approval"] = "reject_to_protocol_for_approval";
    ApplicationStatus["rejected"] = "rejected";
    ApplicationStatus["submitted"] = "submitted";
    ApplicationStatus["under_council_consideration"] = "under_council_consideration";
    ApplicationStatus["withdrawn"] = "withdrawn";
})(ApplicationStatus || (ApplicationStatus = {}));
export var AttachmentImageType;
(function (AttachmentImageType) {
    AttachmentImageType["custom"] = "custom";
    AttachmentImageType["illustration"] = "illustration";
    AttachmentImageType["painting"] = "painting";
    AttachmentImageType["photo"] = "photo";
})(AttachmentImageType || (AttachmentImageType = {}));
export var BookingStatus;
(function (BookingStatus) {
    BookingStatus["approved"] = "approved";
    BookingStatus["canceled"] = "canceled";
    BookingStatus["pending"] = "pending";
    BookingStatus["rejected"] = "rejected";
    BookingStatus["submitted"] = "submitted";
})(BookingStatus || (BookingStatus = {}));
export var BookingType;
(function (BookingType) {
    BookingType["direct"] = "direct";
    BookingType["interest"] = "interest";
})(BookingType || (BookingType = {}));
export var ChangeLogEntityTable;
(function (ChangeLogEntityTable) {
    ChangeLogEntityTable["academic_calendar_event"] = "academic_calendar_event";
    ChangeLogEntityTable["annual_report_collector"] = "annual_report_collector";
    ChangeLogEntityTable["annual_report_question_response"] = "annual_report_question_response";
    ChangeLogEntityTable["application"] = "application";
    ChangeLogEntityTable["application_council_group"] = "application_council_group";
    ChangeLogEntityTable["application_note"] = "application_note";
    ChangeLogEntityTable["application_recipient"] = "application_recipient";
    ChangeLogEntityTable["booking"] = "booking";
    ChangeLogEntityTable["branch_office"] = "branch_office";
    ChangeLogEntityTable["contract_information"] = "contract_information";
    ChangeLogEntityTable["council_group"] = "council_group";
    ChangeLogEntityTable["cultural_institution"] = "cultural_institution";
    ChangeLogEntityTable["document"] = "document";
    ChangeLogEntityTable["document_template"] = "document_template";
    ChangeLogEntityTable["event"] = "event";
    ChangeLogEntityTable["location"] = "location";
    ChangeLogEntityTable["participant"] = "participant";
    ChangeLogEntityTable["production"] = "production";
    ChangeLogEntityTable["production_budget_item"] = "production_budget_item";
    ChangeLogEntityTable["production_group"] = "production_group";
    ChangeLogEntityTable["production_note"] = "production_note";
    ChangeLogEntityTable["registration_group"] = "registration_group";
    ChangeLogEntityTable["school"] = "school";
    ChangeLogEntityTable["school_group"] = "school_group";
    ChangeLogEntityTable["school_schedule"] = "school_schedule";
    ChangeLogEntityTable["tour"] = "tour";
    ChangeLogEntityTable["tour_survey_collector"] = "tour_survey_collector";
    ChangeLogEntityTable["tour_todo_item_state"] = "tour_todo_item_state";
    ChangeLogEntityTable["user"] = "user";
    ChangeLogEntityTable["user_role"] = "user_role";
})(ChangeLogEntityTable || (ChangeLogEntityTable = {}));
export var DerivedBookingStatus;
(function (DerivedBookingStatus) {
    DerivedBookingStatus["approved"] = "approved";
    DerivedBookingStatus["canceled"] = "canceled";
    DerivedBookingStatus["interest_not_satisfied"] = "interest_not_satisfied";
    DerivedBookingStatus["interest_satisfied"] = "interest_satisfied";
    DerivedBookingStatus["pending"] = "pending";
    DerivedBookingStatus["rejected"] = "rejected";
    DerivedBookingStatus["submitted"] = "submitted";
})(DerivedBookingStatus || (DerivedBookingStatus = {}));
export var EventStatus;
(function (EventStatus) {
    EventStatus["canceled_by_administrator"] = "canceled_by_administrator";
    EventStatus["canceled_by_performer"] = "canceled_by_performer";
    EventStatus["canceled_by_school"] = "canceled_by_school";
    EventStatus["draft"] = "draft";
    EventStatus["scheduled"] = "scheduled";
})(EventStatus || (EventStatus = {}));
export var LocationType;
(function (LocationType) {
    LocationType["digital"] = "digital";
    LocationType["location"] = "location";
    LocationType["school"] = "school";
})(LocationType || (LocationType = {}));
export var ParticipantStatus;
(function (ParticipantStatus) {
    ParticipantStatus["allocated"] = "allocated";
    ParticipantStatus["canceled_by_administrator"] = "canceled_by_administrator";
    ParticipantStatus["canceled_by_performer"] = "canceled_by_performer";
    ParticipantStatus["canceled_by_school"] = "canceled_by_school";
    ParticipantStatus["draft_locked_event"] = "draft_locked_event";
    ParticipantStatus["draft_locked_tour"] = "draft_locked_tour";
    ParticipantStatus["draft_unlocked"] = "draft_unlocked";
})(ParticipantStatus || (ParticipantStatus = {}));
export var ProductionBudgetCategory;
(function (ProductionBudgetCategory) {
    ProductionBudgetCategory["fee"] = "fee";
    ProductionBudgetCategory["materials"] = "materials";
    ProductionBudgetCategory["others"] = "others";
    ProductionBudgetCategory["rdo"] = "rdo";
})(ProductionBudgetCategory || (ProductionBudgetCategory = {}));
export var ProductionBudgetPurpose;
(function (ProductionBudgetPurpose) {
    ProductionBudgetPurpose["development"] = "development";
    ProductionBudgetPurpose["tour"] = "tour";
})(ProductionBudgetPurpose || (ProductionBudgetPurpose = {}));
export var ProductionGroupType;
(function (ProductionGroupType) {
    ProductionGroupType["bonus"] = "bonus";
    ProductionGroupType["optional"] = "optional";
    ProductionGroupType["regular"] = "regular";
})(ProductionGroupType || (ProductionGroupType = {}));
export var RegistrationGroupType;
(function (RegistrationGroupType) {
    RegistrationGroupType["basis_group"] = "basis_group";
    RegistrationGroupType["subject_group"] = "subject_group";
})(RegistrationGroupType || (RegistrationGroupType = {}));
export var RoleType;
(function (RoleType) {
    RoleType["B"] = "B";
    RoleType["C"] = "C";
    RoleType["F"] = "F";
    RoleType["L"] = "L";
    RoleType["M"] = "M";
    RoleType["N"] = "N";
    RoleType["P"] = "P";
    RoleType["S"] = "S";
    RoleType["T"] = "T";
    RoleType["W"] = "W";
})(RoleType || (RoleType = {}));
export var SchoolType;
(function (SchoolType) {
    SchoolType["daycare"] = "daycare";
    SchoolType["lower_secondary"] = "lower_secondary";
    SchoolType["primary"] = "primary";
    SchoolType["primary_and_lower_secondary"] = "primary_and_lower_secondary";
    SchoolType["upper_secondary"] = "upper_secondary";
})(SchoolType || (SchoolType = {}));
export var SubmitBookingValidationErrorCategory;
(function (SubmitBookingValidationErrorCategory) {
    SubmitBookingValidationErrorCategory["allocation_missing_for_event"] = "allocation_missing_for_event";
    SubmitBookingValidationErrorCategory["allocation_unsatisfied"] = "allocation_unsatisfied";
    SubmitBookingValidationErrorCategory["already_booked_tour"] = "already_booked_tour";
    SubmitBookingValidationErrorCategory["conflict_overlapping_schedule"] = "conflict_overlapping_schedule";
    SubmitBookingValidationErrorCategory["duplicate_booking"] = "duplicate_booking";
    SubmitBookingValidationErrorCategory["event_canceled"] = "event_canceled";
    SubmitBookingValidationErrorCategory["event_locking"] = "event_locking";
    SubmitBookingValidationErrorCategory["invalid_contact_person_phone_number"] = "invalid_contact_person_phone_number";
    SubmitBookingValidationErrorCategory["maximum_bookings_reached"] = "maximum_bookings_reached";
})(SubmitBookingValidationErrorCategory || (SubmitBookingValidationErrorCategory = {}));
export var TourStatus;
(function (TourStatus) {
    TourStatus["approved_by_performer"] = "approved_by_performer";
    TourStatus["booking"] = "booking";
    TourStatus["draft"] = "draft";
    TourStatus["finished"] = "finished";
    TourStatus["optimizing"] = "optimizing";
    TourStatus["published_to_participants"] = "published_to_participants";
    TourStatus["published_to_performer"] = "published_to_performer";
})(TourStatus || (TourStatus = {}));
//==============================================================
// END Enums and Input Objects
//==============================================================
