import React, {memo} from "react";
import {Button, Icon} from "@material-ui/core";
import PageConfiguration from "../../utils/PageConfiguration";
import IconText from "../IconText";
import {useTranslation} from "react-i18next";
import ClearFiltersButton from "./filters/ClearFiltersButton";
import VisibleColumnsButtonField from "./VisibleColumnsButtonField";
import SaveButton from "../SaveButton";
import {exportToExcel, ProgramsExportToExcel} from "./DataTableExport";
import {useTypedTranslation} from "../../utils/i18n";
import FiltersWrapper from "./filters/FiltersWrapper";

export default React.memo(props => {
    const {headerActions, title, filters, onNew, clearFilterDisabled, clearFilters, visibleColumns, onVisibleColumnsChange, columns, getData} = props;
    const {t} = useTranslation();

    return <>

        {title ? <PageConfiguration title={title}/> : null}


        {filters.length
            ? <div
                style={{display: 'flex', flexFlow: 'wrap', padding: '12px 20px 8px'}}>

                {onNew
                    ? <Button variant={"outlined"} color={"primary"} style={{margin: '8px'}} onClick={onNew}
                              id={"DataTableNewButton"}>
                        <IconText icon={'add'} text={t('new')}/>
                    </Button>
                    : null}

                <FiltersWrapper style={{display: 'inline', margin: '8px'}}>
                  {filters}
                </FiltersWrapper>

                <div style={{flex: '1'}}/>
                {headerActions}
                <ClearFiltersButton
                    disabled={clearFilterDisabled}
                    onClick={clearFilters}
                />
                <VisibleColumnsButtonField
                    columns={columns}
                    value={visibleColumns}
                    onChange={onVisibleColumnsChange}
                />
                <DownloadButton
                    title={title}
                    getData={getData}
                    columns={columns}
                />
            </div>
            : null}
    </>;
});

export const DownloadButton = memo(({title, getData, columns}) => {
    const {t} = useTypedTranslation();

    return <SaveButton
        startIcon={<Icon>cloud_download</Icon>}
        saveLabel={t("common", "export_excel")}
        onClick={async () => {
            const data = await getData();
            const url = await exportToExcel({
                title,
                data,
                columns,
                shouldRunByCron: data?.shouldRunByCron,
                exportServiceParams: data?.exportServiceParams
            }, t);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.xlsx');
            document.body.appendChild(link);
            link.click();
        }}
    />
});

export const ProgramsDownloadButton = memo(({title, columns, data}) => {
    const {t} = useTypedTranslation();

    return <SaveButton
        startIcon={<Icon>cloud_download</Icon>}
        saveLabel={t("common", "export_excel")}
        onClick={async () => {
            const url = await ProgramsExportToExcel({
                title,
                data,
                columns,
                shouldRunByCron: data?.shouldRunByCron
            }, t);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.xlsx');
            document.body.appendChild(link);
            link.click();
        }}
    />
});

/**

 class DownloadButton extends React.PureComponent {
    render() {
        const {loading} = this.state || {};
        const {handleDownload} = this.props;
        if (!handleDownload)
            return null;

        return <>
            <Button
                onClick={async () => {
                    this.setState({loading: true});
                    await handleDownload();
                    this.setState({loading: false});
                }}
                color={"primary"}
            >
                <Icon style={{marginRight: '8px'}}>cloud_download</Icon>
                Eksporter
            </Button>

            {loading
                ? <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open
                    message={'Laster ned...'}
                />
                : null}
        </>;
    }
}

 */